<template>
  <div class="page-container">
    <div class="search-row">
      <div class="left-span">
        <!-- <Select v-model="searchData.jsdId" closable style="width: 200px">
          <Option
            :value="item.id"
            v-for="(item, index) in jisandian"
            :key="index"
          >
            {{ item.name }}
          </Option>
        </Select> -->
        <Cascader
          change-on-select
          v-model="searchData.regionId"
          :data="areas"
          :load-data="getSubArea"
          @on-change="cascaderChange"
        ></Cascader>
      </div>
      <div class="right-span">
        <span
          @click="() => (dateType = 'day')"
          :class="{ 'current-search-type': dateType == 'day' }"
        >
          日
        </span>
        <span
          @click="() => (dateType = 'month')"
          :class="{ 'current-search-type': dateType == 'month' }"
        >
          月
        </span>
        <span
          @click="() => (dateType = 'year')"
          :class="{ 'current-search-type': dateType == 'year' }"
          >年
        </span>
        <span>
          <DatePicker
            placement="bottom-end"
            transfer
            type="daterange"
            v-model="searchData.dateRange"
            @on-change="dateChange"
          ></DatePicker>
        </span>
      </div>
    </div>
    <div class="data-type-options">
      <span :class="{ 'current-search-type': tabType == '1' }" @click="tabList('1')">畜禽粪污收纳总量统计</span>
      <span :class="{ 'current-search-type': tabType == '2' }" @click="tabList('2')">秸秆收纳总量统计</span>
      <span :class="{ 'current-search-type': tabType == '3' }" @click="tabList('3')">销售收纳总量统计</span>
      <!-- <span :class="{ 'current-search-type': tabType == '4' }" @click="tabList('4')">生物有机肥产量统计</span>
      <span :class="{ 'current-search-type': tabType == '5' }" @click="tabList('5')">有机肥库存</span> -->
    </div>
    <div>
      <div id="myChart" style="width:100%;height:300px"></div>
    </div>

    <div style="margin-top:30px">
      <Table :columns="columns" :data="data1"></Table>
    </div>

    <div style="text-align: end;">
        <Page :total="page.total" :pageSize="page.pageSize" />
      </div>
  </div>
</template>
<script>
import CU from "@/common/util";
import * as echarts from "echarts"
import moment from "moment";

export default {
  data() {
    return {
      areas: [],
      //   jisandian: [],
      searchData: {
        regionId: [], //集散点
        dateRange: [new Date(), new Date()],
      },
      dateType: "month",
      tabType:'1',
      data:[],
      useAmountData:[],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      data1:[],
      columns: [
        {
          title: "养殖场名称",
          key:'farmName'
        },
        {
          title:"所属养殖户",
          key:"farmerName"
        },
        {
          title: "区域",
          key: "regionName",
        },
        {
          title: "畜禽粪污收纳总量(吨)",
          key: "manureStorageAmount",
        },
        {
          title:"记录时间",
          key:"recordTime"
        }
      ],
    };
  },
  watch:{
    dateType(n){
      if(this.searchData.regionId.length){
        this.getFecesData()
      }
    },
  },
  methods: {
    // 查询集散点
    // getJisan() {
    //   this.$post(this.$api.BASE_MANAGE.LIST, {
    //     siteType: "3",
    //     pageNo: 1,
    //     pageSize: 99999,
    //   }).then((rs) => {
    //     this.jisandian = rs.list;
    //   });
    // },
    // 查询地区
    getArea(parentId) {
      return this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId,
      });
    },
    getSubArea(item, callback) {
      item.loading = true;
      this.getArea(item.value)
        .then((list) => {
          if (!list || list.length === 0) {
            item.children = [{ value: -1, label: "空", disabled: true }];
          } else {
            item.children = list.map((it) => ({
              value: it.id,
              label: it.name,
              children: [],
              loading: false,
            }));
          }
        })
        .finally(() => {
          if (!item.children || item.children.length === 0)
            item.children = [{ value: -1, label: "空", disabled: true }];
          item.loading = false;
          callback();
        });
    },
    //初始化Echarts
    initEcharts() {
      let myChart = echarts.init(document.getElementById("myChart"));
      myChart.setOption({
        grid: {
          top: 20,
          left: 50,
          right: 50,
          bottom: 20,
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          data: this.data,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#EEE8E8",
              type: "dashed",
            },
          },
          boundaryGap: false,
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              color: "#f6f6f6",
            },
          },
        },
        color: ["#7A40F2"],
        series: {
          type: "line",
          smooth: true,
          data: this.useAmountData,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "rgba(122, 64, 242, 0.4)",
                },
                {
                  offset: 0.5,
                  color: "rgba(122, 64, 242, 0)",
                },
                {
                  offset: 1,
                  color: "rgba(122, 64, 242, 0)",
                },
              ],
            },
          },
          lineStyle: {
            shadowOffsetY: 10,
            shadowColor: "rgba(122, 64, 242, 0.3)",
            shadowBlur: 4,
          },
        },
      });
    },
    //粪污收纳量
    getFecesData(){
      if (this.searchData.regionId.length) {
        let url 
      if(this.tabType=='1') {
        url = this.$api.TOTAL_TABE.FECES_ACCEPT
      }else if(this.tabType=='2'){
        url = this.$api.TOTAL_TABE.STRAW
      }else if(this.tabType=='3'){
        url = this.$api.TOTAL_TABE.SELL
      }
      let regionCode = this.searchData.regionId[0]
      console.log('url',url)
      this.$post(url,{
        	regionCode:regionCode,
          type:this.dateType,
          startTime:moment(this.searchData.dateRange[0]).format("YYYY-MM-DD") + " 00:00:00",
          endTime:moment(this.searchData.dateRange[1]).format("YYYY-MM-DD") + " 23:59:59",
      }).then((res)=>{
        this.data = Object.keys(res.timeLine)
        this.useAmountData = Object.values(res.timeLine)
        this.data1=res.list
        console.log(this.data1)
        this.initEcharts()
      })
      }
      
    },
    dateChange(){
      this.getFecesData()
    },
    tabList(type){
      this.tabType=type
      this.getFecesData()
    },
    cascaderChange(){
      this.getFecesData()
    }
  },
  mounted() {
    // this.getJisan();
    this.getArea(500000).then((res) => {
      this.areas = res.map((item) => ({
        value: item.id,
        label: item.name,
        children: [],
        loading: false,
      }));
    });
    this.initEcharts()
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>